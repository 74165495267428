@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@watersport/ui-styles/dist/css/watersport-style.min.css';

body {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  color: #0c0b19;
}
